// Here you can add other styles
@import '@fontsource/pt-sans';
@import "@fontsource/rajdhani";
@import "@fontsource/signika";

html,
body {
  font-family: 'PT Sans';
  color: $dark !important;
}

.logo {
  font-family: "Rajdhani";
}

.table {
  font-family: "Signika";
}

.pointer {
  cursor: pointer;
}